import React from "react" //, {useEffect} from "react"
import { graphql } from "gatsby"
import MarketoForm from "../components/MarketoForm"
import { Image, Background } from "../components/VindiciaComponents"
import Layout from "../components/layout"
import Header from "../components/Header/index.js"
import TwoColumnGeneric from "../components/Content/TwoColumnGeneric"
import SEO from "../components/seo"
import WaveSVG from "../images/wave.svg"
import { withPreview } from "gatsby-source-prismic"

//const ContactUs = ({location, data}) => {

class ContactUs extends React.Component {
  constructor(props) {
    super(props)
    const location = props.location
    const data = props.data
    this.triggerOnSuccess = this.triggerOnSuccess.bind(this)
    this.state = {
      formIsVisible: true,
    }
  }

  triggerOnSuccess() {
    this.setState({ formIsVisible: false })
  }

  threeColumnSection(section) {
    switch (section.slice_label) {
      case "text":
        return (
          <section
            key={section.primary.section_id}
            id={section.primary.section_id}
            className={`uk-section uk-margin-remove uk-section-primary-light uk-light technical-support-info`}
          >
            <Image
              src={WaveSVG}
              alt="wave"
              style={{
                height: "80px",
                width: "100%",
              }}
            />
            <div className="content">
              <div className="uk-container">
                <div
                  className={`uk-grid uk-grid-divider uk-child-width-1-3@m`}
                  data-uk-grid
                  data-uk-height-match="target: div > .uk-panel"
                >
                  {section.items.map((item, i) => (
                    <div key={`content-${i}`} className="uk-panel uk-overflow-hidden">
                      <div
                        className="uk-card-body"
                        dangerouslySetInnerHTML={{ __html: item.item_copy.html }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        )
      case "panel":
        return (
          <section
            key={section.primary.section_id}
            id={section.primary.section_id}
            className={`uk-section uk-padding-remove uk-margin-remove`}
          >
            <div className="uk-container uk-container-expand uk-padding-remove">
              <div
                className={`uk-grid uk-grid-collapse uk-child-width-1-3@m`}
                data-uk-grid
                data-uk-height-match="target: div > .uk-panel"
              >
                {section.items.map((item, i) => (
                  <div
                    key={`content-${i}`}
                    className={`uk-panel uk-overflow-hidden ${i === 1 ? `dummy` : `uk-visible@s`}`}
                  >
                    <Background src={item.image.fluid.src} style={{ backgroundPosition: "center" }}>
                      <div
                        style={{
                          width: "100%",
                          height: "250px",
                        }}
                      />
                    </Background>
                  </div>
                ))}
              </div>
            </div>
          </section>
        )
      case "card":
        return (
          <section
            key={section.primary.section_id}
            id={section.primary.section_id}
            className={`uk-section uk-section-xsmall`}
          >
            <div className="uk-container">
              <div
                className={`uk-grid uk-grid-row-medium uk-grid-match uk-child-width-1-3@m`}
                data-uk-grid
                data-uk-height-match="target: div > .uk-card > div > p"
              >
                {section.items.map((item, i) => (
                  <div key={`content-${i}`} className="uk-card uk-card-collapse">
                    {item.image.fluid?.src && (
                      <div>
                        <Image {...item.image.fluid} />
                      </div>
                    )}
                    <div
                      className="uk-card-body"
                      dangerouslySetInnerHTML={{ __html: item.item_copy.html }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </section>
        )
      default:
        return null
    }
  }

  contentType(section) {
    let t
    const type = section.slice_type

    switch (type) {
      case "2-column-generic":
        t = (
          <TwoColumnGeneric key={`section-${section.id}`} data={section}>
            <div className="uk-grid uk-grid-medium uk-grid-match uk-child-width-1-2@m" data-uk-grid>
              <div>
                <div dangerouslySetInnerHTML={{ __html: section.primary.column_one.html }} />
              </div>
              <div className={`uk-margin-remove`}>
                <div className="uk-card uk-card-default uk-margin-remove">
                  <h2 className="uk-card-header">Get started:</h2>
                  <div className="uk-card-body">
                    {this.state.formIsVisible && (
                      <div id="form-box">
                        <MarketoForm formId="1573" triggerOnSuccess={this.triggerOnSuccess} />
                      </div>
                    )}
                    {!this.state.formIsVisible && (
                      <div id="success-box">
                        <h3>Thank you</h3>
                        <p>Message was delivered successfully</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </TwoColumnGeneric>
        )
        break
      case "3-column-generic":
        t = this.threeColumnSection(section)
        break
      default:
        break
    }
    return t
  }

  render() {
    const sections = this.props.data.prismicContactUsPage.data
    return (
      <Layout location={this.location}>
        <SEO
          title="Expert Payment Recovery with AI & Revenue Optimizatio"
          description="Contact Vindicia for AI driven payment recovery solution. Recover lost revenue, minimize churn, and optimize subscriber value with our advanced, risk-free solution."
          keywords="contacts"
        />
        <Header
          image={sections.hero_banner_image}
          title={sections.hero_title.text}
          subtitle={sections.hero_subtitle}
          text_alignment={sections.hero_text_alignment || "left"}
          hero_button_link={sections.hero_button_link}
          hero_button_label={sections.hero_button_label}
        />
        {sections.body.map((section, i) => this.contentType(section))}
      </Layout>
    )
  }
}

export default withPreview(ContactUs)

export const contactQuery = graphql`
  {
    prismicContactUsPage {
      data {
        custom_page_title
        hero_title {
          text
        }
        hero_subtitle
        hero_text_alignment
        hero_button_label
        hero_button_link
        hero_banner_image {
          fluid(maxWidth: 1400) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
        body {
          ... on PrismicContactUsPageBody2ColumnGeneric {
            id
            primary {
              section_id
              section_name
              column_one {
                html
              }
              column_two {
                html
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicContactUsPageBody3ColumnGeneric {
            slice_label
            slice_type
            primary {
              section_id
              section_name
            }
            items {
              item_copy {
                html
              }
              image {
                alt
                fluid(maxWidth: 800) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
    allPrismicEmbedForm(filter: { uid: { eq: "contact-form" } }) {
      edges {
        node {
          data {
            form_name
            form_code
          }
        }
      }
    }
  }
`
